<template>
  <div class="alerts-page">
    <div class="alert_header">
      <div class="left" @click="goBack">
        <img src="../../images/back.png" alt="Back Button" class="back-button"/>
        <span class="title">Alerts</span>
      </div>
    </div>

    <div class="filter-buttons">
  
      <button 
        @click="filterAlerts('Pending')" 
        :class="['pending-button', { active: currentFilter === 'Pending' }]"
      >
        Pending (0)
      </button>
      <button 
        @click="filterAlerts('Resolved')" 
        :class="['completed-button', { active: currentFilter === 'Resolved' }]"
      >
        Resolved (1)
      </button>
    </div>

    <div class="cards">
      <div v-for="alert in filteredAlerts" :key="alert.id" class="alert-card">
        <div class="alert-text-group left">
          <div v-for="(text, index) in alert.leftTexts" :key="index" class="alert-text">
            {{ text }}
          </div>
        </div>
        <div class="alert-text-group right" style="text-align: right">
          <div v-for="(text, index) in alert.rightTexts.slice(0, -1)" :key="index" class="alert-text">
            {{ text }}
          </div>
          <div :class="['status-container', getStatusContainerClass(alert.status)]">
            <span class="status-text">
              {{ alert.rightTexts.slice(-1)[0] }} 
              <img 
                :src="getStatusImage(alert.status)" 
                alt="Status Icon" 
                class="status-icon"
              >
            </span>
          </div>
        </div>
      </div>
      <div v-if="filteredAlerts.length === 0">
        <br>
        <br>
        <h1 v-if="systemOnline" style="font-size: 100px">🌇</h1>
        <h1 v-if="!systemOnline" style="font-size: 100px">🎑</h1>
        <h6 v-if="!systemOnline">Night Time. System Off!</h6>
        <h6 v-if="systemOnline">Day time. System Online!</h6>
        <hr>
        <h3>System Normal</h3>
        <p>Vision AI is scanning all inverter parameters for any issues or preventive maintenance required. This is to assure maximum return of investment is achieved by ensuring system is optimal.</p>
        <br>
        <br>
       <div style="background: palegoldenrod;padding: 10px;border-radius: 5px;text-align: left; display:none">
         <h6 style="text-align: left">Warnings Under Assessment</h6>
         <hr>
         <p>The specific yield is good but lower for Jaipur in general. Vision AI will process the information for few days to determine if it is an actual issue or weather/environmental implication.</p>
       </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import data from '../store/testdata';
import testdata from "../store/testdata";

export default {
    props: {
    goBack: Function,
  },

  name: 'AlertsPage',
  setup() {
    const router = useRouter();
    const systemOnline = ref(testdata.systemOnline());
    const alerts = ref([
      { id: 1, status: 'Resolved', leftTexts: ['From', 'Start Time', 'End Time', 'Description', 'Status'], rightTexts: ['Vision AI', '18 Sept, 2024 13:00 IST', '18 Sept, 2024 16:00 IST', 'System detected that generation went down for the specified time period. Later on it was analyzed to be environmental phenomenon based on regional analysis and marked resolved. System verified to run as desired next day.', 'Resolved'] },
      // { id: 2, status: 'Info', leftTexts: ['Status', 'Current Observation'], rightTexts: ['System Normal', 'Vision AI is scanning all inverter parameters for any issues or preventive maintenance required. This is to assure maximum return of investment is achieved by ensuring system is optimal.'] },
      // { id: 3, status: 'Resolved', leftTexts: ['From', 'Start Time', 'End Time', 'Status'], rightTexts: ['Owner',  '14 Dec 2021 10:50:35', '14 Dec 2021 10:50:36', 'Resolved'] },
      // { id: 4, status: 'Pending', leftTexts: ['From','Start Time', 'End Time', 'Status'], rightTexts: ['Owner',  '14 Dec 2021 10:50:35', '14 Dec 2021 10:50:36', 'Pending'] },
    ]);

    const currentFilter = ref('Pending');

    const filterAlerts = (status) => {
      currentFilter.value = status;
    };

    const filteredAlerts = computed(() => {
      let filtered =  alerts.value.filter(alert => alert.status === currentFilter.value);
      if(filtered.length === 0 && currentFilter.value === 'Pending') {
        filtered = alerts.value.filter(alert => alert.status === 'Info');
      }
      return filtered;
    });

    const getStatusImage = (status) => {
      return status === 'Resolved'
        ? require('../../images/completed.svg')  
        : require('../../images/pending.svg');   
    };

    const getStatusContainerClass = (status) => {
      return status === 'Resolved' ? 'completed-status' : (status === 'Pending' ?'pending-status' : '');
    };

   

    return {
      alerts,
      currentFilter,
      filterAlerts,
      filteredAlerts,
    
      getStatusImage,
      getStatusContainerClass,
      systemOnline
    };
  },
};
</script>




<style scoped>
.alerts-page {
  padding: 20px 20px 10px;
}

.alert_header {
  display: flex;
  
  margin-bottom: 20px;
  position:fixed;
  width:100%;
  background-color: #ffffff;
  z-index: 999999;
  .left {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
}

.back-button {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
}

.title {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-weight: 700;
}

.filter-buttons {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10%;
}

.filter-buttons button {
  margin-right: 10px;
  padding: 6px 16px;
  border: none;
  cursor: pointer;
  border-radius: 7px;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.completed-button {
  background-color: #F4F4F4;
  color: #7F7F7F;
}

.pending-button {
  background-color: #F4F4F4;
  color: #7F7F7F;
}

.filter-buttons button.active {
  color: #fff;
}

.filter-buttons button.active.completed-button {
  background-color: #13B78C;
}

.filter-buttons button.active.pending-button {
  background-color: #F73737;
}

.cards {
  display: flex;
  justify-content: space-between;
  padding: 18px 18px;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: #F4F4F4;
  font-size: 12px;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
}
.cards h3{
  font-size: 18px;
  color: #000;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.cards h6{
  font-size: 14px;
  color: #000;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.alert-card {
  display: flex;
  justify-content: space-between;
  padding: 18px 18px;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: #F4F4F4;
}

.alert-text-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.alert-text-group.left {
  align-items: flex-start;
  font-size: 12px;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  gap: 10px;
}

.alert-text-group.right {
  align-items: flex-end;
  font-size: 12px;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  gap: 10px;
}

.alert-text {
  margin-bottom: 5px;
  font-size: 12px;
}

.status-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 7px;
}

.completed-status {
  background-color: #13B78C;
}

.pending-status {
  background-color: #F73737;
}

.status-text {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

.status-icon {
  width: 13px;
  height: 13px;
}
</style>
