import ApiCoreService from "./api.core.service";
import UserService from "@/services/user.service";


const today = new Date().getDate() - 8;
export default class DataService extends ApiCoreService {
    static get token() {
        const token = localStorage.getItem("_token"); // Fetch from localStorage
        return token ? token : null;
    }

    static get userId() {
        const userId = localStorage.getItem("_id"); // Fetch from localStorage
        return userId ? userId : null;
    }

    static async getSiteDetail() {
        let node;
        if (localStorage.getItem('tnode')) {
            node = JSON.parse(localStorage.getItem('tnode'));
        } else {
            node = (await UserService.getCurrentUserNode()).shift();
            localStorage.setItem('tnode', JSON.stringify(node));
        }
        if (node) {
            const inverter = node.node.hardwareGroups[0].devices[0];
            return {
                deviceId: node.meterType + ' (' + node.no + ')',
                inverter: inverter.hardwareType.brand.name.str + ' (' + inverter.hardwareType.model.str + ')',
                imei: inverter.uid,
                sim: inverter.id,
            }
        } else return {
            deviceId: "-",
            inverter: "-",
            imei: "-",
            sim: "-",
        };
    }

    static async getAvailableProducedSoldData() {
        const node = (await UserService.getCurrentUserNode()).shift();
        const sales = (await UserService.getSalesForTheDay()).reduce((p, c) => p + c.units, 0);
        if (node) {
            return {
                available: (node.averageDailyExport - sales).toFixed(2),
                produced: node.averageDailyProduction.toFixed(2),
                sold: sales.toFixed(2)
            }
        } else return {
            available: '-',
            produced: '-',
            sold: '-'
        };

    }


    static async getData() {
        try {
            return await this.graphCall(
                "withAuth.getData",
                `{
           withAuth(token: "${this.token}"){
                  getData(date:"${today}")
            }
            }`
            );
        } catch (c) {
            throw new Error(c);
        }
    }
}
