import LoginService from "./login.service";

export default class AuthService {

    // Check if user is logged in by verifying if the token exists in localStorage
    static isLoggedIn() {
        const token = localStorage.getItem('_token');
        return token ? true : false;
    }

    // Fetch the current user using LoginService's getCurrentUser function
    static async getUser() {
        try {
            const user = await LoginService.getCurrentUser();
            return user;
        } catch (error) {
            // If there's an error, log the user out by clearing the relevant localStorage items
            localStorage.removeItem('_token');
            throw error;
        }
    }
}
