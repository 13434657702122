import ApiCoreService from "./api.core.service";

export default class LoginService extends ApiCoreService {
    static async sendOtp(phoneNumber) {
        try {
            return await this.graphCall('sendOtp', `{
                sendOtp(emailOrPhone: "${phoneNumber}") {
                    success
                    ctx
                }
            }`);
        } catch (c) {
            throw new Error(c);
        }
    }

    static async sendAggOtp(phoneNumber, email, aggregatorCode) {
        try {
            return await this.graphCall('sendOtpForAggregator', `{
                sendOtpForAggregator(email: "${email}" phone: "${phoneNumber}" aggregatorCode: "${aggregatorCode}") {
                    success
                    ctx
                }
            }`);
        } catch (c) {
            throw new Error(c);
        }
    }

    static async verifyOtp(otp, ctx) {
        try {
            return await this.graphCall(
                "verifyOtp",
                `{
                verifyOtp(otp: "${otp}", ctx: "${ctx}") {
                    success
                    token
                    id
                }
            }`,
                {}
            );
        } catch (c) {
            throw new Error(c);
        }
    }

    static get token() {
        const token = localStorage.getItem('_token'); // Fetch from localStorage
        return token ? token : null;
    }

   

   
}
