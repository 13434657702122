<template>
  <div>
    <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
      :height="400"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'BarChart',
  components: { Bar },
  data() {
    return {
      chartData: {
        labels: ["Q1", "Q2", "Q3", "Q4"],
        datasets: [
          {
            label: 'Dataset 1',
            data: [0,0,0,0],
            backgroundColor: '#6EC5D6',
            borderRadius: 0,
          },
          {
            label: 'Dataset 2',
            data: [0,0,0,0],
            backgroundColor: '#019FE0',
            borderRadius: 0,
          },
          {
            label: 'Dataset 3',
            data: [0,0,0,0],
            backgroundColor: '#004B84',
            borderRadius: 0,
          },
          {
            label: 'Dataset 4',
            data: [0,0,0,0],
            backgroundColor: '#4D6591',
            borderRadius: 0,
          }
        ]
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return context.raw + ' MWh'; 
              }
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: 1.75,
            ticks: {
              stepSize: 0.25,
              callback: function(value) {
                return value + ' MWh'; 
              },
              font: {
                size: 10
              }
            }
          },
          x: {
            grid: {
              display: false
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              fontSize: 10,
              gap: 0,
            }
          }
        },
        layout: {
          padding: {
            top: 5,
            bottom: 20
          }
        },
        barPercentage: 0.5,
        categoryPercentage: 0.8
      }
    }
  },
  mounted() {
    this.adjustBarPercentage();
    window.addEventListener('resize', this.adjustBarPercentage);
  },
  methods: {
    adjustBarPercentage() {
      if (window.innerWidth < 600) {
        this.chartOptions.barPercentage = 0.3;
        this.chartOptions.categoryPercentage = 0.6;
        this.chartOptions.scales.x.ticks.fontSize = 8;
      } else {
        this.chartOptions.barPercentage = 0.5;
        this.chartOptions.categoryPercentage = 0.8;
        this.chartOptions.scales.x.ticks.fontSize = 10;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustBarPercentage);
  }
}
</script>

<style scoped>
</style>
