<template>
    <div class="modal" v-if="isVisible">
      <div class="modal-content">
        <header class="wallet-header" @click="close" >
          <img src="../../images/back.png" alt="Back Button" class="back-button"/>
          <h1>Wallet</h1>
        </header>
        <div  class="consumption_card performance">
            <img src="../../images/rectangle.png" alt="Top Right Image" class="top-right-image"/>

  <div class="wallet-info">
    <p class="wallet-balance">Your Wallet Balance</p>
    <p class="wallet-date">As On {{new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(new Date()).replace(/\//g, '-')}}</p>
  </div>
  <div class="wallet-amount">
    <span v-if="walletBalance"> ₹{{walletBalance}}</span>
  </div>
</div>
        <div class="transaction-history">
          <h2>Transaction History</h2>
          <table>
            <thead>
              <tr>
                <th width="90">Date</th>
                <th>Amount</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="transaction in transactions">
                <td class="transaction-date">{{ new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(new Date(parseInt(transaction.ts, 10))).replace(/\//g, '-')}}</td>
                <td class="transaction-amount" :style="'color:' + (transaction.amount > 0 ? 'green': 'red')">{{ transaction.amount }}</td>
                <td class="transaction-type">{{ transaction.desc }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button class="close-modal" @click="close">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  import UserService from "@/services/user.service";

  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        walletBalance: '',
        transactions: [],
      };
    },
    async mounted() {
      this.walletBalance = await UserService.getWalletBalance();
      this.transactions = await UserService.getWalletTransactions();
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding:20px 10px 10px;
    border-radius: 5px;
    width: 100%;
    height: 100vh;
    max-width: 600px;
  }
  
  .wallet-header {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .wallet-header h1 {
    font-size: 16px;
    font-weight:700; 
    margin:0;

    
  }
  
  .back-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .transaction-history {
    margin-top: 20px;
  }
  
  .transaction-history h2 {
    color: #453a94;
    text-align: left;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
font-size: 12px;
  }
  
  th {
    background-color: #f2f2f2;
    color: #453a94;
  }
  
  .close-modal {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #453a94;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .performance {
  background: linear-gradient(to right, #050011, #120B41);
  color: #ffffff;
  position: relative;
  z-index: 123;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  margin-top: 20px;
  border-radius: 20px;

  .wallet-info {
    display: flex;
    flex-direction: column;

    .wallet-balance {
      font-size: 1.2rem;
      font-weight: bold;
      color: #ffffff;
      margin: 0;
    }

    .wallet-date {
      font-size: 0.9rem;
      color: #F1fb5c;
      margin: 5px 0 0;
      text-align: left;

    }
  }

  .wallet-amount {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    text-align: right;
  }

  .top-right-image {
    position: absolute;
    top: 0;
    right: 0px;
    width: 40%;
    height: auto;
    border-radius: 20px;
    z-index: -2;
    @media screen and (width: 600px) and (height: 1024px) {
      width: 30%;

    }
    @media screen and (width: 820px) and (height: 1180px) {
      width: 20%;

    }
    @media screen and (width: 768px) and (height: 1024px) {
      width: 20%;

    }
    @media screen and (width: 1024px) and (height: 1366px) {
      width: 15%;

    }

  }
}

  </style>
  