<template>
    <div class="modal">
      <div class="iframe-content">
        <span class="close" @click="$emit('close')">&times;</span>
        <iframe :src="url" width="100%" height="600px" frameborder="0"></iframe>
        <button @click="$emit('close')" class="submit-button">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      url: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000; 
  }
  
  .iframe-content {
    background-color: #fff;
    padding: 20px;

    width: 100%; 
    max-width: 600px; 
    position: relative;
    animation: zoomIn 0.5s ease-in-out;
}


@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
  
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  
  .submit-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #453A94;
    color: white;
    cursor: pointer;
  }
  .submit-button:hover {
    background-color: #4554a0;
  }
  </style>
  