<template>
  <div class="main_address">
    <div class="address-page">
      <div class="rectangle_add_images">
        <img src="../../images/rectangle.png" alt="rectangles" />
      </div>
      <div class="content">
        <h2>Scan QR</h2>
      </div>
    </div>
    <div class="inverter_image" v-if="!showScanner">
      <img src="../../images/inverter.png" alt="" />
      <div class="arrow_image">
        <img src="../../images/arrow.png" alt="" />
      </div>
    </div>
    <div class="label" v-if="!showScanner">
      <p>Label can be seen on the panel <br />Scan to view Inverter Details.</p>
    </div>
    <router-link to="/scanqr">

    <div class="button-container" v-if="!showScanner">
      <button class="scan-button">Scan Now</button>
    </div>
    </router-link>
    <div style="margin-top: 10px;" v-if="!showScanner">
      <button class="show_me" @click="showPopup = true">Show me what to scan</button>
    </div>
    <div v-if="showScanner" class="qr_scanner">
      <img src="../../images/close.png" alt="Close QR Scanner" class="close-icon" @click="toggleScanner" />
      <div v-if="showScanner" class="qr-scanner-container">
        <div class="qr-scanner-box">
          <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
        </div>
        <p class="scan-text">Label can be seen on the panel <br> Scan to view Inverter details.</p>
      </div>
    </div>

    <div v-if="showPopup" class="popup">
      <div class="popup-content">
        <img src="../../images/scanner_image.jpg" alt="Example" class="popup-image" />
        <p class="popup-text">Label can be seen on the panel <br> Scan to view Inverter details.</p>
        <img src="../../images/cross.svg" alt="Close" class="close-popup" @click="showPopup = false" />
      </div>
    </div>
  </div>
</template>



<script>
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    StreamBarcodeReader
  },
  data() {
    return {
      showScanner: false,
      showPopup: false
    };
  },
  methods: {
    startScanning() {
      this.showScanner = true;
      this.showPopup = false;
    },
    toggleScanner() {
      this.showScanner = !this.showScanner;
    },
    onDecode(text) {
      console.log(`Decode text from QR code is ${text}`)
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`)
    },
    onInit(promise) {
      promise.then(() => {
        console.log('Successfully initialized QR scanner');
      }).catch(error => {
        console.error('Error initializing QR scanner', error);
        if (error.name === 'NotAllowedError') {
          alert('Camera access was denied.');
        } else if (error.name === 'NotFoundError') {
          alert('No camera found.');
        } else {
          alert(`Error initializing QR scanner: ${error.message}`);
        }
      });
    }
  }
};
</script>



<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

.address-page {
  max-width: 100%;
  height: 30vh;
  margin: auto;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to right, #050011, #120b41);
  overflow-x: hidden;
  position: relative;
  padding: 20px;
}

.rectangle_add_images {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.rectangle_add_images img {
  height: 200px;
  width: 336px;
}

.content {
  position: absolute;
  top: 30%;
  left: 28px;
  color: white;
  text-align: left;
}

h2 {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
  margin-top: 22px;
}

.show {
  font-size: 12px;
  font-family: "Montserrat";
}

.inverter_image {
  position: relative;
  margin-top: 50px;
  left: -5px;
}

.inverter_image img {
  object-fit: contain;
}

.arrow_image {
  position: absolute;
  top: 60%;
  left: 20%;
}

.label {
  padding-left: 5px;
  font-size: 12px;
  font-family: "Montserrat";
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.scan-button {
  background-color: #453a94;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-family: "Montserrat";
  width: 65%;
  margin-top: 45px;
}

.show_me {
  background-color: #F4F4F4;
  border: none;
  color: #453a94;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-family: "Montserrat";
  width: 65%;
  margin: auto;
}

.scan-button:hover {
  background-color: #7a6ed6;
}

.qr-scanner-container {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
}

.qr-scanner-box {
  border: 0.25px solid rgb(156, 155, 155);
  width: 250px;
  height: 250px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 10px;
}

.scan-text {
  color: #000000;
  margin-top: 10px;
  font-size: 12px;
  font-family: "Montserrat";
  text-align: center;
}

.close-icon {
  position: absolute;
  top: 40%;
  right: 10%;
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 5%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.popup-content {
  background: white;
  padding: 40px 22px;
  border-radius: 10px;
  position: relative;
  text-align: center;
  max-width: 90%;
}

.popup-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-bottom: 20px;
}

.popup-text {
  font-family: "Montserrat";
  font-size: 14px;
}

.close-popup {
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}

@media (min-width: 568px) {
  .arrow_image {
    left: 30%;
  }
}

@media (min-width: 768px) {
  .arrow_image {
    left: 36%;
  }
}

@media (min-width: 992px) {
  .arrow_image {
    left: 39%;
  }
}
</style>

