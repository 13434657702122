<template>
  <div>
    <Bar
      id="my-chart-id"
      v-if="loaded" 
      :options="chartOptions"
      :data="chartData"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import data from '../store/testdata';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    range: {
      type: String,
      required: false
    },
    labels: {
      type: Array,
      required: false
    },
    data: {
      type: Array,
      required: false
    },
    unit: {
      type: String,
      required: false
    },
    colours: {
      type: Array,
      required: false
    }
  },
  data() {
    const that = this;
    return {
      chartData: {
        labels: this.labels || ["05 AM", "06 AM", "07 AM", "08 AM", "09 AM", "10 AM", "11 AM", "12 PM",
          "01 PM", "02 PM", "03 PM", "04 PM", "05 PM", "06 PM", "07 PM", "08 PM", "09 PM"]
        ,
        datasets: [
          {
            data: Array(17).fill(0),
            backgroundColor: this.colours || '#453A94',
            borderRadius: 7, 
          }
        ]
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return context.raw + (that.unit || ' kWh');
              }
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            // max: 2,
            ticks: {
              stepSize: 0.2,
              font: {
                size: 7    // Adjust the size as needed
              },
              callback: function(value) {
                if (value === 0) return '0';
                return value + (that.unit ||' kWh');
              }
            }
          },
          x: {
            grid: {
              display: false
            },
            ticks: {
              autoSkip: true,
              maxRotation: 90,
              minRotation: 60,
              fontSize: 5,
              font: {
                size: 7    // Adjust the size as needed
              },
              // gap: 20,
              maxTicksLimit: 10
            }
          }
        },
        layout: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        barPercentage: 0.5,
        categoryPercentage: 0.8
      },
      loaded: false
    }
  },
  async mounted() {
  try {
    const newData = this.data ? this.data : await data.todayTotal() === 0 ? await data.loadYesterdayGraph() : await data.loadTodayGraph();
    this.chartData.datasets[0].data = newData;
    this.loaded = true;
    window.addEventListener('resize', this.adjustBarPercentage);
  } catch (error) {
    console.error('Error loading chart data:', error);
  }
},

  methods: {
    adjustBarPercentage() {
      // if (window.innerWidth < 600) {
      //   this.chartOptions.barPercentage = 0.3;
      //   this.chartOptions.categoryPercentage = 0.6;
      //   this.chartOptions.scales.x.ticks.fontSize = 5;
      // } else {
      //   this.chartOptions.barPercentage = 0.5;
      //   this.chartOptions.categoryPercentage = 0.8;
      //   this.chartOptions.scales.x.ticks.fontSize = 5;
      // }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustBarPercentage);
  }
}
</script>

<style scoped>
p {
  margin-top: 40px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}
</style>