<template>
  <div class="leaflet-map">
    <div ref="map" class="map-container"></div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';

export default {
  name: 'LeafletMap',
  props: {
    center: {
      type: Array,
      required: true,
      default: () => [28.679079, 77.069710]
    },
    zoom: {
      type: Number,
      default: 13
    },
    address: {
      type: String,
      default: '123, ABC Street, Lajpat Nagar'
    },
    showTooltip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      map: null,
      marker: null,
      currentAddress: this.address
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      this.map = L.map(this.$refs.map, {
        zoomControl: false,
        attributionControl: false
      }).setView(this.center, this.zoom);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: ''
      }).addTo(this.map);

      const customIcon = L.icon({
        iconUrl: require('../../images/marker.svg'),
        iconSize: [25, 70],
        iconAnchor: [22, 94],
        popupAnchor: [-3, -76]
      });

      this.marker = L.marker(this.center, { icon: customIcon }).addTo(this.map);

      if (this.showTooltip) {
        this.updatePopupContent(this.currentAddress);
      }

      this.map.on('click', (event) => {
        this.moveMarker(event.latlng);
      });
    },
    async moveMarker(latlng) {
      this.marker.setLatLng(latlng);
      this.map.setView(latlng, this.zoom, { animate: true });
      
      try {
        const address = await this.getAddress(latlng.lat, latlng.lng);
        this.currentAddress = address;
        this.updatePopupContent(address);
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    },
    async getAddress(lat, lng) {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
      return response.data.display_name || 'Unknown location';
    },
    updatePopupContent(address) {
      const popupContent = `
        <div style="text-align: center;">
          <p>${address}</p>
          <button style="
            background-color: #453a94;
            border: none;
            color: white;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 9px;
            font-family: 'Montserrat';
            display: block;
            margin: 0 auto;
          " onclick="alert('Set New Address')">Update this Address</button>
        </div>
      `;
      this.marker.bindPopup(popupContent).openPopup();
    }
  }
};
</script>

<style scoped>
.leaflet-map {
  height: 219px;
  margin-bottom: 20px;
}
.map-container {
  width: 100%;
  height: 219px;
}
</style>