<template>
  <div class="page-container">
    <div class="left" @click="goBack">
      <img src="../../images/white_back.svg" alt="" class="back-button"/>
      <span class="textTite">Back</span>
    </div>
    <div class="background-container">
      <img src="../../images/Complete.png" alt="Center Image" class="center-image"/>
      <p class="text_congo">Congratulations</p>
      <p class="you_saved">you saved</p>
      <p class="text_amount">₹ {{ savedMoney }}</p>
    </div>
    <div class="button-container">
      <h1>Top Prosumers</h1>
    </div>
    <div class="cards-container">
      <div class="cards-wrapper">
        <div class="card">
          <div class="image-container">
            <img src="../../images/jade_blue.png" alt="Image Description"/>
            <div class="overlay-text">A</div>
          </div>
          <div class="text">15 km Away</div>
          <div class="amount-container">
            <div class="amount">₹ 30,000</div>
            <div class="saved">Saved in 1 month</div>
          </div>
        </div>
        <div class="card two">
          <div class="image-container">
            <img src="../../images/Jade Orange.png" alt="Image Description"/>
            <div class="overlay-text">S</div>
          </div>
          <div class="text">15 km Away</div>
          <div class="amount-container second">
            <div class="amount">₹ 30,000</div>
            <div class="saved">Saved in 1 month</div>
          </div>
        </div>
        <div class="card">
          <div class="image-container">
            <img src="../../images/jade_blue.png" alt="Image Description"/>
            <div class="overlay-text">A</div>
          </div>
          <div class="text">15 km Away</div>
          <div class="amount-container">
            <div class="amount">₹ 30,000</div>
            <div class="saved">Saved in 1 month</div>
          </div>
        </div>
        <div class="card two">
          <div class="image-container">
            <img src="../../images/Jade Orange.png" alt="Image Description"/>
            <div class="overlay-text">S</div>
          </div>
          <div class="text">15 km Away</div>
          <div class="amount-container second">
            <div class="amount">₹ 30,000</div>
            <div class="saved">Saved in 1 month</div>
          </div>
        </div>

      </div>
    </div>
    <div class="closeBtnBottom" @click="goBack">Close</div>
  </div>
</template>
<script>
import {ref, computed} from 'vue';
import {useRouter} from 'vue-router';

export default {
  name: 'Transaction',
  mounted(){
    this.savedMoney = this.$route.query.savedMoney;
  },
  data(){
    return{
      savedMoney: 0,
    }
  },
  setup() {
    const router = useRouter();

    const goBack = () => {
      router.back();
    };

    return {
      goBack,
    };
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.left {
  position: absolute;
  z-index: 1000;
  padding: 20px 10px 10px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  .textTite {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
  }
}
.closeBtnBottom{
  position: absolute;
  z-index: 1000;
  display: flex;
  font-weight: 700;
  align-items: center;
  bottom: 38px;
  margin: auto;
  background: red;
  right: 0;
  left: 0;
  width: 84px;
  gap: 8px;
  text-align: center;
  padding: 10px 20px;
  color: #fff;
  border-radius: 100px;
}
.back-button {
  margin-left: 10px;
  width: 20px;
  height: auto;
  cursor: pointer;
}

.page-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(to right, #050011, #120B41);
}

.background-container {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  background-image: url("../../images/congratulations.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 56px;
}

.center-image {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}

.text_congo {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.text_amount {
  color: #ffffff;
  font-size: 32px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.you_saved {
  font-size: 14px;
  color: #13B78C;
  font-weight: 600;
  margin: 0;
}

.button-container {
  position: absolute;
  top: calc(12% + 40%);
  left: 25%;
  transform: translateX(-50%);
}

.button-container h1 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
}

.top_prosumers {
  background: linear-gradient(to right, #00F2B2, #8D7EFD);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 50px;
}

.cards-container {
  position: absolute;
  width: 100%;
  top: 55%;
  display: flex;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.cards-wrapper {
  display: flex;
  width: calc(197px * 2 * 2 + 10px * 3);
  animation: shuffle 10s linear infinite;
}

.card {
  background-color: #8D7EFD;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 197px;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}

.card:last-child {
  margin-right: 0;
}

.image-container {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 60px auto 10px;
}

.image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: white;
}

.amount-container {
  background-color: #5A4DC2;
  padding: 10px;
  margin-top: auto;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

}

.text {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-weight: 600;

  margin-bottom: 20px;
}

.amount {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-weight: 700;
}

.saved {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;

  margin-bottom: 10px;
}

.two {
  background-color: #00F2B2;
}

.second {
  background-color: #13B78C;
}


@keyframes shuffle {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>
  
