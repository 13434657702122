<template>
  <div class="main_address">
    <div class="address-page">
      <div class="rectangle_add_images">
        <img src="../../images/rectangle.png" alt="rectangles" />
      </div>
      <div class="content">
        <h3>Welcome <span>Nisha!</span></h3>
        <h2>Set<br>Address</h2>
      </div>
    </div>
    <div class="form-container">
      <div class="input-group">
        <label for="siteName" class="site_name">Enter Site Name</label>
        <input type="text" id="siteName" v-model="siteName" required class="address_input"/>
      </div>
      
      <p class="site_name">Type</p>
      <div class="type">
        <button class="type-button">Residential</button>
        <button class="type-button">Commercial</button>
      </div>

      <div class="input-group">
        <label for="address" class="site_name">Address</label>
        <input type="text" id="address" v-model="address" required>
      </div>
      <div class="location">
        <img src="../../images/location.png" alt="">
        <p>Map</p>
      </div>
      <leaflet-map :center="[28.679079, 77.069710]" :zoom="12"></leaflet-map>

      <router-link to="/connect_to_inverter">
        <button class="next-button">Next</button>

      </router-link>

    </div>
<BuySellCards/>
  </div>
</template>

<script>
import LeafletMap from '@/components/Mapcard.vue'; 

export default {
  components: {
    LeafletMap,
  },
  data() {
    return {
      siteName: '',
      address: ''
    };
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

.address-page {
  max-width: 100%;
  height: 30vh;
  margin: auto;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  background: linear-gradient(to right, #050011, #120b41);
  overflow-x: hidden;
  position: relative;
  padding: 20px;
}

.rectangle_add_images {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.rectangle_add_images img {
  height: 200px;
  width: 336px;
}

.content {
  position: absolute;
  top: 30%;
  left: 28px;
  color: white;
  text-align: left;
}

.content span {
  color: #8D7EFD;
}

h3, h2 {
  margin: 0;
  font-weight: normal;
}

h3 {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 20px;
}

h2 {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
  margin-top: 22px;
}

h3 span {
  font-weight: bold;
}

.form-container {
  padding: 20px;
  margin-top: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.site_name {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  font-family: 'Montserrat';
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  flex: 1;
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
  border-radius:5px;
}

.type {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.sitename_p {
  font-size: 12px;
  text-align: left;
  font-weight: 700;
  font-family: 'Montserrat';
}

.type-button {
  background-color: #F4F4F4;
  border: none;
  color: #000000;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Montserrat';
}

.type-button:hover {
  background-color: #453a94;
  color:#ffffff;
}

.google-map {
  height: 300px;
  width: 100%;
  margin-bottom: 20px;
}

.next-button {
  background-color: #453a94;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  font-family: 'Montserrat';

}



.location {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.location p {
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: 600;
  margin-bottom:5px;
}


</style>
