<template>
  <div class="splash-screen">
    <div class="content">
      <img src="../../images/tri_splash_2.png" alt="First Image" class="image"/>
    </div>
    <div class="privacy-policies">
      <div class="privacy">Privacy Policy</div>
      <div>|</div>
      <div class="terms">Terms & Conditions</div>
    </div>
  </div>
</template>

<script setup>
import {onMounted} from 'vue';
import {useRouter} from 'vue-router';

const router = useRouter();

onMounted(() => {
  setTimeout(() => {
   router.push({name: 'login'});
  }, 1000);
});
</script>

<style scoped>
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #4B0A9F, rgba(75, 10, 159, 0.5)),
  url('../../images/splash_bg.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
}

.content {
  text-align: center;
  margin-top: 22vh;
}

.image {
  display: block;
  margin: 10px auto;
  max-width: 50%;
  height: auto;
}

.privacy-policies {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'Montserrat';
  color: #ffffff;
  font-size: 12px;
}

.privacy, .terms {
  padding: 5px;
}
</style>
  