<template>
    <div class="main_address">
      <div class="address-page" >
        <div class="rectangle_add_images">
          <img src="../../images/rectangle.png" alt="rectangles" />
        </div>
        <div class="content">
          <h2>
            Successfully <br> Added
          </h2>
        </div>
      </div>
      <p class="success">Successfully Added</p>
      <div class="solar_home">
        <img src="../../images/solar.png" alt="">
        <img src="../../images/+.png" alt="">
        <img src="../../images/tri.png" alt="">

      </div>
      <p class="data_logger"> Trillectric data Logger and  Inverter <br> Details has successfully added.</p>
      <div class="button-container">
        <router-link to="/homepage">
  <button class="scan-button view">View</button>
</router-link>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    components: {
    },
    data() {
      return {
      };
    },
  }
  </script>
  
  <style scoped>
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
  
  body {
    font-family: "Montserrat", sans-serif;
  }
  
  .address-page {
    height: 30vh;
    margin: auto;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background: linear-gradient(to right, #050011, #120b41);
    overflow-x: hidden;
    position: relative;
    padding: 20px;
  }
  
  .rectangle_add_images {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  
  .rectangle_add_images img {
    height: 200px;
    width: 336px;
  }
  
  .content {
    position: absolute;
    top: 30%;
    left: 28px;
    color: white;
    text-align: left;
  }
  
  h2 {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
    margin-top: 22px;
  }
  
  .show {
    font-size: 12px;
    font-family: "Montserrat";
  }
  
  .inverter_image {
    position: relative;
    margin-top: 50px;
    left: -5px;
  }
  
  .inverter_image img {
    object-fit: contain;
  }
  
  .left_arrow_image {
    position: absolute;
    top: 76%;
    left: 55%;
  }
  
  .label {
    padding-left: 5px;
    font-size: 12px;
    font-family: "Montserrat";
  }
  .label p{
    margin-right:18%;
  }
  .scan-button.view {
    background-color: #453a94;
    border: none;
    color: white;
    padding: 9px 53px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    font-family: "Montserrat";
    margin-top: 45px;
  }
  
 
  
  .qr-scanner-container {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .qr-scanner-box {
    border: 0.25px solid rgb(156, 155, 155);
    width: 250px;
    height: 250px;
  }
  
  .scan-text {
    color: #000000;
    margin-top: 10px;
    font-size: 12px;
    font-family: "Montserrat";
    text-align: center;
  }
  
  .view-button {
    background-color: #453a94;
    border: none;
    color: #ffffff;
    padding: 10px 35px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-family: "Montserrat";
    margin-top: 10px;
    text-transform: uppercase;
  }
  
  .close-button:hover {
    background-color: #7a6ed6;
  }
  .success{
    font-size: 20px;
    font-weight: 600;
    color: #453A94;
    font-family: "Montserrat";
   margin-top: 100px;
  }
  .solar_home{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  .data_logger{
    font-size: 14px;
    font-family: "Montserrat";
    margin-top: 50px;

  }
  .button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  </style>
  