import axios from "axios";

// REMOTE URL
// const API_BASE_URL = Config.API_BASE_URL;

export default class ApiCoreService {

  static async graphCall(name, query, params) {
    try {
      let result = await this.post(name, `https://api.trillectric.com/api/graph-api`, {
        query,
        variables: params,
      });
      result = result.data;
      if (result.errors) {
        const newError = new Error();
        newError.source = name;
        newError.details = result.errors;
        newError.message = result.errors.map((error) => error.message);
        throw newError;
      }
      const xpath = name.split(".");
      let filtered = result.data;
      xpath.forEach((key) => (filtered = filtered[key]));
      return filtered;
    } catch (c) {
      if (c.details) throw c;
      const newError = new Error(c);
      newError.source = name;
      newError.details = c;
      newError.message =
        "Error running graph api for " + name + ". " + c.message;
      throw newError;
    }
  }

  static get token() {
  const token = localStorage.getItem('_token'); // Fetch from localStorage
  return token ? token : null;
  }

  static async post(name, url, body) {
    return axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: (name==='sendOtp' || name==='verifyOtp' || name === 'sendOtpForAggregator')? "": `Bearer ${this.token}`,
      },
      withCredentials: true, // This enables sending cookies with the request
    });
  }
}