<template>
  <div class="chart-container">
    <Line :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, Filler, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, Filler, CategoryScale, LinearScale);

export default {
  components: {
    Line,
  },
  data() {
    return {
      chartData: {
        labels: ['4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM', '8:00 AM', '9:00 AM'],
        datasets: [
          {
            data: [0, 100, 200, 80, 150, 0],  
            backgroundColor: 'rgba(0, 0, 255, 0.2)',
            borderColor: 'rgba(0, 0, 255, 1)',
            borderWidth: 1,
            fill: true,
            pointRadius: 0,
            tension: 0.10,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value) {
                return value;  
              },
              padding: 10,
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90
            },
          },
          y: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 300,
            ticks: {
              callback: function (value) {
                if (value === 250) {
                  return '250 kWh';  
                }
              },
            },
            grid: {
              display: false,  
            },
          },
        },
        plugins: {
          legend: {
            display: false,  
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.raw + ' kWh'; 
              },
            },
          },
        },
      },
      chartHeight: 200,
      chartWidth: 131,
    };
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 190px;
  top: 10px;
  max-width: 1000px;
  margin: auto;
}
</style>
