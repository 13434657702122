<template>
  <div>
    <Line
      id="area-chart-id"
      v-if="loaded"
      :options="chartOptions"
      :data="chartData"
    />
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import data from '../store/testdata';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);


export default {
  name: 'AreaChart',
  components: { Line },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Generation',
            data: [],
            backgroundColor: 'rgba(11, 51, 91, 0.4)', 
            borderColor: 'rgba(11, 51, 91, 1)',
            fill: 'origin',
            tension: 0.4,
            pointRadius: 0,
          },
          {
            label: 'Generation Consumed',
            data: [],
            backgroundColor: 'rgba(0, 168, 191, 0.4)', 
            borderColor: '#00A8BF',
            fill: 'origin',
            tension: 0.4,
            pointRadius: 0,
          },
          {
            label: 'Exported Power',
            data: [],
            backgroundColor: 'rgba(0, 242, 178, 0.4)', 
            borderColor: '#00F2B2',
            fill: 'origin',
            tension: 0.4,
            pointRadius: 0,
          },
          {
            label: 'Total Consumption',
            data: [],
            backgroundColor: 'rgba(157, 191, 253, 0.4)', 
            borderColor: 'rgba(157, 191, 253, 1)',
            fill: 'origin',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            // max: 15,
            ticks: {
              stepSize: 5,
              callback: function(value) {
                if (value < 0) {
                  return Math.abs(value) + ' kWh';
                } else {
                  return value + ' kWh';
                }
              },
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: false,
            },
          },
        },
      },
      loaded: false,
    };
  },
  async mounted() {
    try {
      const gd = await data.holisticGraph();
      this.chartData.labels = gd.labels;
      this.chartData.datasets[0].data = gd.totalGen;
      this.chartData.datasets[1].data = gd.usedGen;
      this.chartData.datasets[2].data = gd.exportedGen;
      this.chartData.datasets[3].data = gd.totalCon;
      this.loaded = true;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
</style>
