// store.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    selectedCard: localStorage.getItem('selectedCard') || ''
  },
  mutations: {
    setSelectedCard(state, card) {
      console.log( card,"selected");
      state.selectedCard = card;
      localStorage.setItem('selectedCard', card);
    }
  },
  actions: {
    updateSelectedCard({ commit }, card) {
      commit('setSelectedCard', card);
    }
  },
  getters: {
    selectedCard: (state) => state.selectedCard
  }
});

export default store;
