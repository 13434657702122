<template>
  <div class='sitepage_content'>
    <div>
    <div class="site_container">
      <div class="left" @click="goBack">
        <img src="../../images/back.png" alt="Back Button" class="back-button"/>
        <span class="text">Site</span>
      </div>
      <div class="right">

        <div class="circle-container">
          <div class="circle orange" @click="toggleText">
            <span class="circle-text">2</span>
          </div>
          <div v-if="showText" class="additional-text">
            No Sites Communication
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="date_wrapper">
      <div class="system-overview">
        <div class="date-range">
          <div class="date-selector">
            <select v-model="selectedDate" @change="handleDateChange" class="date-select">
              <img src="../../images/buy_down.png" alt="dropdown">
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="past7">Past 7 Days</option>
              <option value="mtd">Month to Date</option>
              <option value="month">Past Month</option>
            </select>
          </div>
          <div class="date-input-wrapper">
            <div class="date-input-container">
              <input
                  type="date"
                  v-model="startDate"
                  class="date-input"
                  ref="startDateInput"
                  min="2024-09-15"
              />
              <span class="arrow">→</span>
              <input
                  type="date"
                  v-model="endDate"
                  class="date-input"
                  ref="endDateInput"
                  :min="startDate"
                  :max="new Date().toISOString().split('T')[0]"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
    <p style="text-align: left; margin-top: 10px; color:grey; font-size: 12px"> Last Updated 48 mins ago</p>


    <div class="production_consumption">
      <div class="consumption_card">
        <h3>Production</h3>
        <p>{{ generation.toFixed(2) }} <span>kWh</span></p>
        <div class="content-row">
          <div class="item">
            <div class="blue-dot green"></div>
            <img src="../../images/book.svg" alt="">
            <p>{{ generationSelf.toFixed(2) }} kWh</p>
          </div>
          <div class="item">
            <div class="blue-dot"></div>
            <img src="../../images/tower.svg" alt="">
            <p>{{ generationGrid.toFixed(2) }} kWh</p>
          </div>
        </div>
        <div style="margin-top: 10px;">
          <CProgressStacked>

            <CProgress class="custom-progress" :value="generationProgress"/>
            <CProgress class="custom-progress-blue" :value="100 - generationProgress"/>
          </CProgressStacked>
        </div>

      </div>
   
  <div v-if="consumptionData" class="consumption_card">
        <h3>Consumption</h3>
        <p>{{ consumption }} <span>kWh</span></p>
        <div class="content-row">
          <div class="item">
            <div class="blue-dot green"></div>
            <img src="../../images/book.svg" alt="">
            <p>{{ consumptionSelf }} kWh</p>
          </div>
          <div class="item">
            <div class="blue-dot "></div>
            <img src="../../images/tower.svg" alt="">
            <p>{{ consumptionGrid }} kWh</p>
          </div>

        </div>
        <div style="margin-top: 10px;">
          <CProgressStacked>

            <CProgress class="custom-progress-light" :value="consumptionProgress"/>
            <CProgress class="custom-progress-dark" :value="100-consumptionProgress"/>
          </CProgressStacked>
        </div>

      </div>
      <div v-else class="consumption_card performance">
    <h3>Performance</h3>
    <img src="../../images/rectangle.png" alt="Top Right Image" class="top-right-image"/>
    
      <p>{{ specificYield.toFixed(2) }}
        <span v-if="specificYield >= 3.5" style="color: lawngreen;font-size: 1rem">(GOOD)</span>
        <span v-if="specificYield >= 3 && specificYield < 3.5 " style="color: orange;font-size: 1rem">(OK)</span>
        <span v-if="specificYield < 3" style="color: red;font-size: 1rem">(BAD)</span>
      </p>
      <p style="margin-top: 26px; font-size: 12px">
        Specific Yield (kWh/kWp)
      </p>

  



  </div>
  <div class="consumption_card environment">
<img src="../../images/green_env1.png" alt=" " class='env_right_image'>
    <div class='environment_circle'>
      <div class="env_circle">
        <img src="../../images/tree_env.svg" alt="">
      </div>
      <div class="env_content">
        Planted <br/> trees
        <br/>
       <p style="font-size: 16px;">10</p>

  </div>    </div>
 
</div>

      <div class="consumption_card environment impact">
        <img src="../../images/orange_env1.png" alt="" class='env_right_image'>

 
        <div class='environment_circle'>
      <div class="env_circle_second">
        <img src="../../images/co2.svg" alt="">
      </div>
  <div class="env_content">
        Carbon <br/> Reduction 
        <br/>
       <p style="font-size: 16px;">5 kg</p>

  </div>
    </div>
       </div>
     

    </div>
    
    <div  v-if="consumptionData" class="performance_yield">
      <h4>Performance</h4>
      <img src="../../images/rectangle.png" alt="Top Right Image" class="top-right-image"/>
      <div style="text-align:right;">
        <p>{{ specificYield.toFixed(2) }}
          <span v-if="specificYield >= 3.5" style="color: lawngreen;font-size: 1rem">(GOOD)</span>
          <span v-if="specificYield >= 3 && specificYield < 3.5 " style="color: orange;font-size: 1rem">(OK)</span>
          <span v-if="specificYield < 3" style="color: red;font-size: 1rem">(BAD)</span>
        </p>
        <p>Specific Yield (kWh/kWp)</p>
      </div>
    </div>
   
    <div class="power_flow_container">
      <div class="power_flow_head">
        <h2>Power Flow</h2>
        <div class="green_power_dot">
          <p>Live</p>
        </div>
      </div>

      <div class="circle-container">
        <div class="circle">
          <img src="../../images/cloud-s.svg" alt="Circular Image" class="circle-image"/>
        </div>
        <p class="circle-description">{{ temperature }}°C</p>
      </div>

      <div class="power-column-container">

        <div class="solar_column">
          <img src="../../images/pwer-solar.svg" alt="">
          <div class="power_text_first">{{ currentPower.toFixed(2) }} kW</div>

        </div>

        <div class="circle-second-column">
          <div class="direction">
            <img src="../../images/green-s.svg" alt="">
          </div>
          <div class="direction_two">
            <img src="../../images/orange.svg" alt="">
          </div>
          <div class="direction_three">
            <img src="../../images/purple-s.svg" alt="">
          </div>
          <div class="column circle-stack">
            <div class="outer-circle">
              <div class="middle-circle">
                <div class="inner-circle">
                  <img src="../../images/home-s.svg" alt="Inner Circle Image" class="circle-image"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="power_third-column">
          <div class="power_text">No EV</div>
          <div class="power_text">{{ currentPowerSelf.toFixed(2) }} kW</div>
          <div class="power_text">{{ currentPowerGrid.toFixed(2) }} kW</div>
        </div>

        <div class="power_fourth-column">
          <div class="solar_column-first" @click="showEvModal = true">
            <img src="../../images/car.svg" alt="">
          </div>

          <div class="solar_column-fourth">
            <img src="../../images/book-s.svg" alt="">
          </div>
          <div class="solar_column-fourth">
            <img src="../../images/tower-s.svg" alt="">
          </div>
        </div>
      </div>
      </div>
      <NewEv v-if="showEvModal" @close="showEvModal = false" />



    <div style="margin-top: 20px;">
      <div class="site_power">
        <div>
          <p>Site Power</p>
        </div>

        <div class="power_energy">
          <div class="slider"></div>
          <button class="button" @click="setActive('power')" :class="{ active: activeButton === 'power' }">Power
          </button>
          <button class="button" style="color: #777" @click="setActive('power')" :class="{ active: activeButton === 'energy' }">Energy
          </button>
        </div>
      </div>
    </div>
    <p style="text-align: left; margin-top: 10px; color:grey; font-size: 12px"> Last Updated {{selectedDate}}</p>

    <div class="graphs-container">
      <div class="graph-cards-row">
        <div class="graph-card productionGraph">
          <h3>Production</h3>
          <div class="content">
            <div class="info">
              <div class="dot green"></div>
              <img src="../../images/date.svg" alt="Production Image"/>
              <p>To Grid</p>
            </div>
            <div class="info">
              <div class="dot blue"></div>
              <img src="../../images/tower.svg" alt="Production Image"/>
              <p>To Load</p>
            </div>
          </div>
        </div>
        <div   v-if="consumptionData" class="graph-card consumptionGraph">
          <h3>Consumption</h3>
          <div class="content">
            <div class="info">
              <div class="dot green"></div>
              <img src="../../images/date.svg" alt="Consumption Image"/>
              <p>From Grid</p>
            </div>
            <div class="info">
              <div class="dot blue"></div>
              <img src="../../images/tower.svg" alt="Consumption Image"/>
              <p>From Generated</p>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-container">
        <AreaChart/>
      </div>
    </div>


    <div class="comparative_energy">
      <p>Generation</p>
    </div>
    <div>
      <BarChart :range="selectedDate" :data="genD.value"/>
      <br>
    </div>

    <div class="comparative_energy">
      <p>Site Performance</p>
    </div>
    <div>
      <BarChart :range="selectedDate"  :labels="perfChartData.labels" :data="perfChartData.sy" :unit="' SY'" :colours="perfChartData.sy.map(val => `rgba(${!(val >=3 && val < 3.5) ? Math.floor(255 * (1 - (val - 0) / (5 - 0))) : 255}, ${Math.floor(255 * ((val - 0) / (5 - 0)))}, 0, 1)`)"/>
      <br>
    </div>

    <div class="comparative_energy">
      <p>Comparative Energy</p>
      <div class="comparative_buttons">
        <div class="slider"></div>
        <button class="button" @click="setComparative('month')" :class="{ active: comparativeButton === 'month' }">
          Month
        </button>
        <button class="button" @click="setComparative('quarter')" :class="{ active: comparativeButton === 'quarter' }">
          Quarter
        </button>
        <button class="button" @click="setComparative('year')" :class="{ active: comparativeButton === 'year' }">Year
        </button>
      </div>
    </div>
    <div>
      <br>Not Enough Data to Plot. <br> Minimum 1 Month Data Required.
    </div>
    <div style="background-color: #F8F8F8; border-radius: 15px; padding: 20px; margin-top:10px">
      <div style="margin-bottom: 20px;">
        <div class="year_dots">
          <div class="dot_year_pair">
            <span class="dot" style="background-color: #6EC5D6;"></span>
            <span class="year">2021</span>
          </div>
          <div class="dot_year_pair">
            <span class="dot" style="background-color: #019FE0;"></span>
            <span class="year">2022</span>
          </div>
          <div class="dot_year_pair">
            <span class="dot" style="background-color: #004B84;"></span>
            <span class="year">2023</span>
          </div>
          <div class="dot_year_pair">
            <span class="dot" style="background-color: #004B84;"></span>
            <span class="year">2024</span>
          </div>
        </div>
      </div>

  <div>
    <ComparitiveEnergyGraph/>
  </div>
</div>


  </div>
</template>


<script>
import { ref, onMounted } from 'vue';
import AreaChart from '@/components/AreaChart.vue';
import BarChart from '@/components/BarChart.vue';
import ComparitiveEnergyGraph from '@/components/ComparitiveEnergyGraph.vue';
import data from '../store/testdata';
import NewEv from '@/components/NewEv.vue'


export default {
  data() {
    return {
      selectedDate: 'today', 
      consumptionData: false,
      showEvModal:false
    };
  },
  components: {
    AreaChart,
    ComparitiveEnergyGraph,
    BarChart,
    NewEv
  },
  async mounted(){
    this.consumptionData = await data.hasConsumptionDataEnabled();
  },
  props: ['goBack'],
  setup(props) {
    const currentView = ref('Home'); 

    const showText = ref(false);
    const selectedDate = ref('today');
    const startDate = ref('');
    const endDate = ref('');
    const startDateInput = ref(null);
    const endDateInput = ref(null);
    const activeButton = ref('power');
    const comparativeButton = ref('month');
    const consumption = ref('N/A');
    const consumptionSelf = ref('00.00');
    const consumptionGrid = ref('00.00');
    const consumptionProgress = ref(60);
    const generation = ref(0);
    const generationSelf = ref(0);
    const generationGrid = ref(0);
    const generationProgress = ref(0);
    const specificYield = ref(data.specificYield());
    const temperature = ref(data.temperature());
    const currentPower = ref(0);
    const currentPowerSelf = ref(0);
    const currentPowerGrid = ref(0);
    // chart
    const perfChartData = ref(data.holisticGraph());
    const genD = ref([]);

    function pow() {
      currentPower.value = data.currentPower();
      if(currentPower.value > 0) currentPower.value = ~~(currentPower.value) + (~~(Math.random() * 100) / 100);
      currentPowerSelf.value = .8 * currentPower.value;
      currentPowerGrid.value = .2 * currentPower.value;
      setTimeout(() => pow(), 5000);
    }

    pow();

    const handleDateChange = () => {
      switch (selectedDate.value) {
        case 'today':
          // Set both startDate and endDate to today's date
          startDate.value = new Date().toISOString().slice(0, 10);
          endDate.value = new Date().toISOString().slice(0, 10);
          generation.value = data.todayTotal();
          genD.value = data.loadTodayGraph();
          break;

        case 'yesterday':
          // Set startDate and endDate to yesterday's date
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          startDate.value = yesterday.toISOString().slice(0, 10);
          endDate.value = yesterday.toISOString().slice(0, 10);
          generation.value = data.yesterdayTotal();
          genD.value = data.loadYesterdayGraph();
          break;

        case 'past7':
          // Set startDate to 7 days ago and endDate to today
          const past7 = new Date();
          startDate.value = new Date(past7.setDate(past7.getDate() - 7)).toISOString().slice(0, 10);
          endDate.value = new Date().toISOString().slice(0, 10); // today's date
          generation.value = data.totalGenerationTracked();
          genD.value = data.loadPastToDTotals();
          break;

        case 'mtd':
          // Set startDate to the first day of the current month and endDate to today
          const now = new Date();
          startDate.value = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().slice(0, 10);
          endDate.value = new Date().toISOString().slice(0, 10); // today's date
          generation.value = data.totalGenerationTracked();
          genD.value = data.loadPastToDTotals();
          break;

        case 'month':
          // Set startDate to the first day of the previous month and endDate to the last day of the previous month
          const currentDate = new Date();
          const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
          const lastDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
          startDate.value = firstDayOfLastMonth.toISOString().slice(0, 10);
          endDate.value = lastDayOfLastMonth.toISOString().slice(0, 10);
          generation.value = data.totalGenerationTracked();
          genD.value = data.loadPastToDTotals();
          break;

        default:
          startDate.value = new Date().toISOString().slice(0, 10);
          endDate.value = new Date().toISOString().slice(0, 10);
          generation.value = data.todayTotal();
          genD.value = data.loadTodayGraph();
      }
      generationSelf.value = generation.value * .8;
      generationGrid.value = generation.value * .2;
      generationProgress.value = 80;
    };

    const openCalendar = () => {
      if (startDateInput.value) {
        startDateInput.value.focus();
      }
      if (endDateInput.value) {
        endDateInput.value.focus();
      }
    };

    const toggleText = () => {
      showText.value = !showText.value;
    };

    const setActive = (button) => {
      activeButton.value = button;
      const slider = document.querySelector('.power_energy .slider');
      const buttonWidth = document.querySelector('.power_energy .button').offsetWidth;
      if (button === 'power') {
        slider.style.transform = `translateX(0)`;
      } else {
        slider.style.transform = `translateX(${buttonWidth}px)`;
      }
    };

    const setComparative = (button) => {
      comparativeButton.value = button;
      const slider = document.querySelector('.comparative_buttons .slider');
      const buttonWidth = document.querySelector('.comparative_buttons .button').offsetWidth;
      const buttons = ['month', 'quarter', 'year'];
      const index = buttons.indexOf(button);
      slider.style.transform = `translateX(${index * buttonWidth}px)`;
    };

    onMounted(() => {
      handleDateChange();
    });

    let pTF;
    pTF = () => {
      consumptionProgress.value = ~~(Math.random() * 100);
      setTimeout(function () {
        pTF();
      }, 5500);
    }
    pTF();

    return {
      showText,
      selectedDate,
      startDate,
      endDate,
      toggleText,
      handleDateChange,
      openCalendar,
      startDateInput,
      endDateInput,
      activeButton,
      setActive,
      comparativeButton,
      setComparative,
      consumption,
      consumptionSelf,
      consumptionGrid,
      consumptionProgress,
      generation,
      generationSelf,
      generationGrid,
      generationProgress,
      specificYield,
      temperature,
      currentPower,
      currentPowerSelf,
      currentPowerGrid,
      perfChartData,
      genD,
      goBack: props.goBack
    };
  },


};
</script>



<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');



.site_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed; 
  top: 0;         
  left: 0;        
  width: 100%;    
  background-color: #fff; 
  padding: 10px 20px; 
  z-index: 999;    


  .left {
    display: flex;
    align-items: center;
    cursor: pointer;
    .back-button {
      margin-right: 10px;
    }

    .text {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .right {
    display: flex;
    align-items: center;
    position: relative;

    .circle-container {
      display: flex;
      align-items: center;
      margin-left: 15px;
      background: #F8F8F8;
      border-radius: 26px;
      padding: 5px;

      .circle {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #453A94;

        &.orange {
          height: 25px;
          width: 25px;
          background-color: orange;
        }

        .circle-text {
          color: white;
          font-weight: bold;
        }
      }

      .additional-text {
        position: absolute;
        top: 45px;
        left: -30px;
        background-color: #000000;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        color: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
}


.date-range {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 60px;
}

.date-selector {
  margin-right: 10px;
}

.date-select {
  padding: 3px;
  font-size: 12px;
  color: #000000;
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.date-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.date-input-container {
  display: flex;
  align-items: center;
}

.date-input {
  width: 90px;
  height: 32px;
  font-size: 12px;
  padding: 0 8px;
  box-sizing: border-box;
  text-align: center;
  line-height: 32px;
  color: #000000;
  background-color: transparent;
  border: none;
  outline: none;
}

.arrow {
  font-size: 18px;
}

.calendar-icon {
  margin-right: 5px;
}

.production_consumption {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  margin-top: 20px;
  text-align: left;
  gap: 15px;

  .consumption_card {
    background-color: #f8f8f8;
    padding: 12px 10px;
    border-radius: 15px;
    width: 191px;
    @media (max-width: 768px) {
      width: 100%;

    }
    @media (max-width: 992px) {
      width: 100%;

    }


    h3 {
      font-size: 12px;
      color: #453A94;
      font-weight: 700;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px;

      span {
        font-size: 10px;
        font-weight: normal;
        color: #6D6D6D;
      }
    }

    .content-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .item {
        display: flex;
        align-items: center;
        gap: 2px;


        .blue-dot {
          width: 4px;
          height: 4px;
          background-color: #0B335B;
          border-radius: 50%;
          margin-right: 2px;
        }

        .green {
          width: 4px;
          height: 4px;
          background-color: #00F2B2;
          border-radius: 50%;
        }


        p {
          margin: 0 0 -3px;
          font-size: 10px;
          font-weight: 400;
          color: #000000;
        }
      }
    }
  }
}

.performance{
  background: linear-gradient(to right, #050011, #120B41);
  color:#ffffff;
  position: relative;
  z-index: 123;

  h3{
    color:#ffffff !important;
  }
  .top-right-image {
    position: absolute;
    top: 0;
    right: 0px;
    width: 60%;
    height: auto;
    border-radius: 20px;
    z-index: -2;
    @media screen and (width: 600px) and (height: 1024px) {
      width: 30%;

    }
    @media screen and (width: 820px) and (height: 1180px) {
      width: 20%;

    }
    @media screen and (width: 768px) and (height: 1024px) {
      width: 20%;

    }
    @media screen and (width: 1024px) and (height: 1366px) {
      width: 15%;

    }

  }
}

.performance_yield {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to right, #050011, #120B41);
  border-radius: 15px;
  color: #ffffff;
  padding: 21px;
  margin: 0;
  position: relative;
  overflow: visible;
  z-index: 123;
  margin-top: 20px;

  h4 {
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 600;
  }

  p:nth-of-type(1) {
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 600;
    margin: 0;
  }

  p:nth-of-type(2) {
    font-size: 12px;
    font-family: "Montserrat";
  }

  .top-right-image {
    position: absolute;
    top: 0;
    right: 0px;
    width: 40%;
    height: auto;
    border-radius: 20px;
    z-index: -2;
    @media screen and (width: 600px) and (height: 1024px) {
      width: 30%;

    }
    @media screen and (width: 820px) and (height: 1180px) {
      width: 20%;

    }
    @media screen and (width: 768px) and (height: 1024px) {
      width: 20%;

    }
    @media screen and (width: 1024px) and (height: 1366px) {
      width: 15%;

    }

  }
}

.site_power {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p:nth-of-type(1) {
    text-align: left;
    font-size: 14px;
    font-family: "Montserrat";
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }

  p:nth-of-type(2) {
    text-align: left;
    font-size: 10px;
    font-family: "Montserrat";
    color: #AAAAAA;
    margin: 0;
  }
}

.power_energy {
  position: relative;
  display: flex;
  align-items: center;
  height: 23px;
  border: 1px solid #453A94;
  border-radius: 20px;
  overflow: hidden;
  width: 150px;

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-color: #453A94;
    transition: transform 0.3s ease;
    border-radius: 20px;
  }

  .button {
    flex: 1;
    border: none;
    background: none;
    color: #000000;
    font-size: 10px;
    font-family: "Montserrat";
    font-weight: 700;
    cursor: pointer;
    z-index: 1;
    text-align: center;
    line-height: 23px;

    &.active {
      color: #ffffff;
    }
  }
}

.graphs-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  background: #F4F4F4;
  padding: 15px;
  border-radius: 15px;

  .graph-cards-row {
    display: flex;
    gap: 15px;
  }

  .graph-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    h3 {
      font-size: 10px;
      font-family: "Montserrat";
      color: #000;
      font-weight: 700;
      margin-bottom: 10px;
      text-align: left;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .info {
        display: flex;
        align-items: center;
        gap: 10px;

        .dot {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #0B335B;
        }

        .blue {
          width: 5px;
          height: 5px;
          background-color: #00F2B2;
          border-radius: 50%;
        }

        p {
          font-size: 10px;
          font-family: "Montserrat";
          color: #000;
          margin: 0;
        }
      }
    }
  }


}


.comparative_energy {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  p {
    font-size: 14px;
    font-family: "Montserrat";
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
  }

  .comparative_buttons {
    position: relative;
    display: flex;
    align-items: center;
    height: 23px;
    border: 1px solid #453A94;
    border-radius: 20px;
    overflow: hidden;

    .slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 37.33%;
      background-color: #453A94;
      transition: transform 0.3s ease;
      border-radius: 20px;
    }

    .button {
      border: none;
      background: none;
      color: #000000;
      font-size: 10px;
      font-family: "Montserrat";

      z-index: 1;

      &.active {
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}

.year_dots {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  width: 70%;
  border-radius: 7px;
  padding: 10px;

  .dot_year_pair {
    display: flex;
    align-items: center;
    margin-right: 15px;

    .dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
    }

    .year {
      font-size: 10px;
      font-family: "Montserrat";

    }
  }
}

.custom-progress {
  --cui-progress-bar-bg: #00F2B2;
}

.custom-progress-blue {
  --cui-progress-bar-bg: #0B335B;
}

.custom-progress-light {
  --cui-progress-bar-bg: #00A8BF;
  transition: width 1.5s ease-in-out;
}

.custom-progress-dark {
  --cui-progress-bar-bg: #9DBFFD;
  transition: width 1.5s ease-in-out; 
}

.power_flow_container {
  background: linear-gradient(to right, #050011, #120B41);
  border-radius: 15px;
  padding: 0px 0px 12px 0px;
  margin-top: 15px;
}

.power_flow_head {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  h2 {
    font-size: 18px;
    font-family: "Montserrat";
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.green_power_dot {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 12px;
    font-family: "Montserrat";
    color: #ffffff;
  }

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #00F2B2;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.circle-container {
  display: flex;
  align-items: center;
  margin-left: 15px;

  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #453A94;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .circle-description {
    margin: 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }
}


.power_flow_container {
  background: linear-gradient(to right, #050011, #120B41);
  border-radius: 15px;
  padding: 0px 0px 12px 0px;
  margin-top: 15px;
}

.power_flow_head {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  h2 {
    font-size: 18px;
    font-family: "Montserrat";
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.green_power_dot {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 12px;
    font-family: "Montserrat";
    color: #ffffff;
  }

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #00F2B2;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.circle-container {
  display: flex;
  align-items: center;
  margin-left: 15px;

  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #453A94;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .circle-description {
    margin: 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }
}

.power-column-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 5px;
  height: 150px;
  z-index: 0;
  @media screen and (width: 600px) and (height: 1024px) {
    width: 70%;

  }

  .column {
    color: #ffffff;
    text-align: center;
  }

  .solar_column {
    background: linear-gradient(to bottom, #0578FF, #48B4F1);
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    margin: 0 auto;
    align-self: center;
    position: relative;
  }


  .solar_column::after {
    content: '';
    content: "";
    position: absolute;
    width: 33px;
    left: 40px;
    height: 2px;
    background: repeating-linear-gradient(to right, #00F2B2, #00F2B2 5px, transparent 5px, transparent 10px);
    animation: glow-dash-21e921b0 3s linear infinite;
  }

  @keyframes glow-dash {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 20px 0;
    }
  }

}

.circle-second-column {
  display: flex;
  padding: 0px 3px;
  position: relative;
  z-index: 2;
}

.direction {
  position: absolute;
  top: 9px;
  left: 82%;
  z-index: -1;

  @media screen and (width: 384px) and (height: 640px) {
    left: 78%;
  }

  @media screen and (width: 360px) and (height: 740px) {
    left: 74%;
  }


}

.direction_two {
  position: absolute;
  top: 37%;
  left: 88%;
  z-index: -1;
  @media screen and (width: 384px) and (height: 640px) {
    left: 82%;

  }
  @media screen and (width: 360px) and (height: 740px) {
    left: 80%;
  }
}

.direction_three {
  position: absolute;
  top: 57%;
  left: 80%;
  z-index: -1;

  @media screen and (width: 384px) and (height: 640px) {
    left: 77%;

  }
  @media screen and (width: 360px) and (height: 740px) {
    left: 72%;
  }
}

.circle-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.outer-circle, .middle-circle, .inner-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

//   .outer-circle {
//   width: 95px;
//   height: 95px;
//   background: radial-gradient(circle, #5949CE, #140779);
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }


.outer-circle {
  width: 95px;
  height: 95px;
  background: radial-gradient(circle, #5949CE, #140779);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0 15px 10px rgba(89, 73, 206, 0.6);
  animation: glowing 1.2s infinite alternate;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 15px 10px rgba(89, 73, 206, 0.6);
  }
  100% {
    box-shadow: 0 0 35px 35px rgba(89, 73, 206, 1);
  }
}


.middle-circle {
  width: 67px;
  height: 67px;
  background-color: #5247A5;
  box-shadow: 0 0 15px 5px rgb(81 76 123 / 60%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-circle {
  width: 41px;
  height: 41px;
  background: linear-gradient(to right, #A498FF, #725FFC);
}


// .circle-image {
//   width: 30px;
//   height: 30px;
// }

.power_third-column {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 20px;
  padding: 5px;
  z-index: 2;
}

.power_text {
  background-color: #453A94;
  font-size: 12px;
  font-family: "Inter";
  color: #ffffff;
  text-align: center;
  height: 20px;
  width: 62px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.power_third-column :nth-child(1) {
  background-color: #00C591;
}

.power_third-column :nth-child(2) {
  background-color: #FF8C06;
}

.power_third-column :nth-child(3) {
  background-color: #8D7EFD;
}


// fourth
.power_fourth-column {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 12px;
  padding: 0px 18px;
  margin-bottom: 15px;
  //   @media screen and (width: 360px) and (height: 740px) {
  // padding: 0px 9px;
  //   }


}

.solar_column-first {
  background: linear-gradient(to bottom, #00F2B2, #008C67);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.power_fourth-column :nth-child(2) {
  background: linear-gradient(to bottom, #FFB179, #FF8C06);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.power_fourth-column :nth-child(3) {
  background: linear-gradient(to bottom, #8D7EFD, #453A94);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.power_text-fourth {
  background-color: #453A94;
  color: #ffffff;
  text-align: center;
  height: 40px;

  border-radius: 5px;
}

.power_text_first {
  position: absolute;
  background: linear-gradient(to bottom, #0578FF, #48B4F1);
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  height: 20px;
  width: 62px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50px;
}
.environment {
  background: linear-gradient(90deg, #00FFBC, #038B67);
  display: flex;
  justify-content: space-between;
  align-items:center;
  position: relative;
  z-index:5;


  .environment_circle{
    padding:15px 0px ;
    display:flex;
    justify-content: space-between;
    align-items:center;
  }

  .env_circle {


    img{
      width:50px
    }

  }
  .env_circle_second{

    img{
      width:50px;
    }
  }

  .env_content {
    flex-grow: 1;
    margin-left: 15px; 
    font-size:14px;
    color:#ffffff;
    font-weight:700;
    font-family: 'Montserrat';

  }
.env_right_image{
  position:absolute;
  top:0;
  right:0;
  z-index:0;

}

}
.impact{
  background: linear-gradient(90deg, #FED86A, #F1A200);
}

@media (max-width: 568px) {
    .sitepage_content {
      padding: 0 20px 10px!important;

      // .production_consumption{
      //   grid-template-columns: auto;
      // }
    }
  }
  @media (min-width: 1024px) {
  

      .production_consumption{
        grid-template-columns: repeat(4,1fr);
      }
      .production_consumption .consumption_card{
        width:100%;
      }
    }
  
</style>
