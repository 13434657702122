<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <div>
        <img src="../../images/new_ev.png" alt="Ev" />
        <p class="integrate">
            Integrate Your EV <br/> with Trillectric

        </p>
      </div>
      <div>
        <p class="setup_two_ev ">Easily manage your EV charging and energy with automatic tracking.
</p>
        <button @click="$emit('close')" class="close-modal">
            Activate EV Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: "NewEv",
  data() {
    return {
      showEvModal: false 
    };
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(175 169 169 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 15px;
}

.modal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  color: #000000;
  position: relative;
  z-index: 1;
  width: 220px;
  animation: zoomIn 0.5s ease-in-out;
}
.modal-content img{
    width:120px;
    height:120px;
    margin-top: -30px;
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.close-modal {
  background-color: #453a94;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  margin: 0 auto;
}

.text_congo {
  color: #00ffbc;
  font-weight: 700;
  margin: 0;
}

.integrate {
  font-size: 15px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: -10px;
  font-family: "Montserrat", sans-serif;
}

.you_saved_ev {
  margin-bottom: 5px;
}
.setup_two_ev {
    margin-bottom: 8px;
  font-size: 9px;
  color: #adadad;
  font-family: "Montserrat", sans-serif;
}
</style>
